p, h2, header, h3, a{
  font-family: "Montserrat-Regular";
}

.main-container {
  max-width: 100vw;
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  background: rgb(0, 0, 0);
  overflow: auto;
  height: 100vh;
  max-height: 100vh;
  align-items: center;

} 

.main-container-inner {
  width: 100vw;
  max-width: 1280px;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  position: relative;
  padding: 7.5px;
  flex-wrap: wrap;
  flex: 1;
  max-height: 791px;
  height: 100vh;
}

.inner-container {
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  background: #000000;
  border-radius: 5px;
  padding: 25px;
}

.logoheader {
  height: 70px;
 align-items: center;
  display: flex;
  position: relative;
 justify-content: space-between;
}

.logo {
  z-index: 9;
  cursor: pointer;
  transition: all 50ms ease-in-out;
  padding-top: 0;
}

.logosvg {
  width: 80px;
  height: 80px;
}


.socials ul {
  list-style: none;
  display: flex;
  gap: 20px;
  cursor: pointer;
  z-index: 9;
}

.github, .email, .linkedin {
  z-index: 9;
  transition: all 50ms ease-in-out;
}


.emailsvg, .linkedsvg, .githubsvg {
  width: 55px;
  height: 55px;

}


.typing {
  animation: blinker 1.5s linear infinite;
  transform: translate(25%, 50%);
  
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.welcome {
  display: block;
  font-size: 1.75em;

}

.title {
  position: static;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  padding-left: 10px;
  font-family: "Montserrat-Regular";
  font-size: 2em;
  color: #fff;
}

.projects-overview {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  min-height: calc(100% - 102px);
  height: calc(100% - 102px);
  display: grid;
  grid-template-columns: 25%, 75%;
  gap: 10px;
  transform: translateZ(0);
  width: 100%;
  justify-content: space-between;
  align-items: top;
  padding-left: 50px;
  
}

.main-message-hide {
 display: none;
 opacity: 0;
}

.main-message {
  position: relative;
  padding-top: 75px;
  grid-column: 2;

}

.main-message span {
  
  color: #fff;
  font-family: "Montserrat Regular";
}

.main-message h1{
  margin-top: 0;
  font-size: 90px;
}


.project-wrapper {
  font-size: 14px;
  padding-top: 100px;
}

.project-wrapper div {
  z-index: 0;
  background: #000000;
  border-top-left-radius: 7px;
  flex-direction: column;
  margin-left: 0;
  padding: 2px 20px 0;
  
}

.main-projects{
  grid-column: 1;
  top: 0;

}

.main-projects-hide{
 opacity: 0;
 display: none;
}

.main-projects-show:hover{
  z-index: 999;

}

.project-list header {
  font-family: "Montserrat-Light";
  font-size: 2.3em;
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  cursor: pointer;
  width: 60%;
}


.project-portfolio {
  color: #fff;
  margin-bottom: 7px;
  margin-top: 7px;
  line-height: 1.5;
  text-decoration: none;
  cursor: pointer;
  /* Swap opacity to 0 for hover effect */
  opacity: 1; 
  transition: opacity 0.3s ease-in-out;
  
}

.project-list:hover .project-portfolio {
  opacity: 1; 
  
}

.project-portfolio:hover, .portfolio-link:hover {
  opacity: 1; 
  color: #fff;
  
}

.portfolio-link {
  font-size: 1.9em;
  font-family: "Montserrat-Thin";
  width: 237px;
}


.portfolio-link:after,
.portfolio-link:before {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 1px solid rgba(255, 255, 255, 0);
  bottom: 0px;
  content: " ";
  display: block;
  margin: 0 auto;
  position: relative;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  width: 0;
  right: 0px;
  
}

.portfolio-link:hover:after {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-color: rgb(81, 33, 194);
  -webkit-transition: width 200ms ease-in-out;
  transition: width 200ms ease-in-out;
  width: 100%;
}

.portfolio-link:hover:before {
  bottom: auto;
  top: 0;
  width: 100%;
}

.extras{
 
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1; 
}

/* Go to top button to be added */
.goTop{
  margin-bottom:20px;
  width: 100px;
  height: 100px;
}

.welcome-message {
  opacity: 0;
  height: 0;
  display: block;
}

.welcome-message-show {
  opacity: 1;
  transition: all 0.3s ease-in-out;
  grid-column: 2;
}

.welcome-message-show span {
  margin-left: 30px;
  font-size: 1.5em;
  font-weight: 100;
  line-height: 1.4;
  display: block;
  position: relative;
}

.individual-project-show {
  opacity: 1;
  width: 100%;
  display: block;
  transition: all 0.3s ease-in-out;
  height: 100%;
  position: relative;
  left: 15px;
  margin-top: -15px;
  
}

.individual-project-show header {
  color: #fff;
  font-size: 2.5em;
  padding-bottom: 20px;
  padding-top: 20px;
  transition: all 0.3s ease-in-out;
  
}

.individual-project-show span {
  font-size: 1.3em;
  
}

.individual-project-show button {
  border: 0 solid #000;
  background-color: #000;
  width: 50%;
  align-items: center;
  position: relative;
  top: 15px;
  margin-bottom:35px;
  left: 15%;
 
}

.individual-project-show a {
  text-align: center;
  color: #fff;
  background: rgb(81, 33, 194);
  border-radius: 20px;
  border: 0 solid #000;
  padding: 8px 0;
  font-size: 20px;
  font-weight: 700;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
  transition: all 0.3s ease-in-out;
  display: block;
  
 
}


.individual-project-show a:hover {
  background-color: rgb(42, 186, 47);
  color: #000;
  border-radius: 3px;
}

.individual-project,  .individual-project p, .individual-project img, .individual-project span, .individual-project p{
  opacity: 0;
  height: 0;
  display: block;
  
}

.individual-project-show h2 {
  margin-bottom: 20px;
  color: #fff;
  
}




.qualys, .databreach, .tableau, .sql, .shinobi, .openmediavault, .critellis, .paradigm {
  width: 80%;
  height: 40%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 25px;
  
}
.projects-overview{
  padding-left: 0px;
}


.hamburger-menu{
  display: none;
}

@media screen and (min-width: 330px) and (max-width: 800px) {
  .welcome {
    font-size: 1.1em;
    padding-bottom: px;
    padding-top: 0px;
  }

  .main-message {
    padding-top: 25px;
    transition: all 0.3s ease-in-out;
  }

  .welcome-message {
    font-size: 0.8em;
    transition-property: opacity, height;
    transition-duration: 0.5s;
    
  }
  
  .main-projects{
    display: none;
    opacity: 0;
  }
  .main-projects-show{
    grid-column: 1;
    top: 0;
    position: sticky;
    transition: all 0.3s ease-in-out;
  }
  .hamburger-menu{
    height: 40px;
    width: 40px;
    display: flex;
    padding-left: 125px;
  
  }
  .main-container {
    padding: 5px;
  }
  .inner-container{
    padding: 0;
    width: 100%;
  }

  .main-container-inner {
    padding: 20px;
  }

  .logosvg {
    width: 60px;
    height: 60px;
  }
  
  .socials ul {
    list-style: none;
    display: flex;
    gap: 20px;
    cursor: pointer;
    z-index: 9;
  }

  
  .emailsvg, .linkedsvg, .githubsvg {
    opacity: 0;
    display: none;

  }
  


  .socials ul {
    gap: 15px;
  }

 
}